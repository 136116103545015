const defaultIpfsApiAddress = {
  "http://localhost:3001": {
    ipfsApiAddress: "https://ipfs-api-tw2.meshstream.io",
    gatewayUrl: "https://ipfs-gateway-hk1.meshstream.io",
  },
  "https://ipfs-webui.meshstream.io": {
    ipfsApiAddress: "https://ipfs-api-tw2.meshstream.io",
    gatewayUrl: "https://ipfs-gateway-hk1.meshstream.io",
  },
  "https://ipfs-webui-hk1.meshstream.io": {
    ipfsApiAddress: "https://ipfs-api-hk1.meshstream.io",
    gatewayUrl: "https://ipfs-gateway-hk1.meshstream.io",
  },
  "https://ipfs-webui-hk2.meshstream.io": {
    ipfsApiAddress: "https://ipfs-api-hk2.meshstream.io",
    gatewayUrl: "https://ipfs-gateway-hk2.meshstream.io",
  },
  "https://ipfs-webui-hk3.meshstream.io": {
    ipfsApiAddress: "https://ipfs-api-hk3.meshstream.io",
    gatewayUrl: "https://ipfs-gateway-hk3.meshstream.io",
  },
  "https://ipfs-webui-hk4.meshstream.io": {
    ipfsApiAddress: "https://ipfs-api-hk4.meshstream.io",
    gatewayUrl: "https://ipfs-gateway-hk4.meshstream.io",
  },
  "https://ipfs-webui-skt1.meshstream.io": {
    ipfsApiAddress: "https://ipfs-api-skt1.meshstream.io",
    gatewayUrl: "https://ipfs-gateway-skt1.meshstream.io",
  },
  "https://ipfs-webui-skt2.meshstream.io": {
    ipfsApiAddress: "https://ipfs-api-skt2.meshstream.io",
    gatewayUrl: "https://ipfs-gateway-skt2.meshstream.io",
  },
  "https://ipfs-webui-sharp.meshstream.io": {
    ipfsApiAddress: "https://ipfs-api-sharp.meshstream.io",
    gatewayUrl: "https://ipfs-gateway-sharp.meshstream.io",
  },
};

export default {
  ipfsApiAddress: defaultIpfsApiAddress[window.location.origin].ipfsApiAddress,
  gatewayUrl: defaultIpfsApiAddress[window.location.origin].gatewayUrl,
};
